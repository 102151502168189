@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.optOut {
  margin-top: $spacing-5;
  display: grid;
  gap: $spacing-2;
  padding: $spacing-5;
  background-color: $achromatic-white;
  border: 1px solid $color-dark-blue-25;
  border-radius: $border-radius-md;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
}
