@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.articleContainer {
  transition: all 0.7s ease-in-out;
  cursor: pointer;
  width: 100%;
}
.bottomBorder {
  border-bottom: 1px solid #e7e7e7;
}
.titleContainer {
  padding-top: $spacing-1;
  position: relative;
  display: flex;
}
.title {
  margin: 8px 0px;
  font-weight: $fw-bold;
  font-size: 16px;
  color: #007cc2;
  text-decoration: underline;
  img {
    margin-bottom: -6px;
  }
}
.detailViewTitle {
  color: #031545;
  margin-bottom: $spacing-3;
}
.details {
  display: flex;
  font-weight: $fw-normal;
  font-size: 14px;
  span {
    margin-right: $spacing-2;
    margin-left: 6px;
  }
}
.link {
  width: 100%;
  text-decoration: none;
  color: #031545;
}
.imageContainer {
  height: 250px;
  width: 100%;
  position: relative;
}
.placeholder {
  background-color: #e5f8f5;
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: $spacing-14;
  }
}
