@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.openCounterWrapper {
  display: flex;
  flex-direction: column;
  min-width: 300px;

  h5 {
    color: $blue-primary-v100;
    font-size: 0.75rem;
    margin: 0px;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: $fw-normal;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  h3 {
    font-size: 1.125rem;
    font-weight: $fw-normal;
    margin-top: 0px;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 600px) {
    margin-left: 0px;
    padding-left: 0px;
    border-left: none;
    width: 100%;
    min-width: 270px;
  }
}
.detailsGrid {
  display: flex;
  align-items: center;
  font-weight: $fw-normal;
  font-size: 0.875rem;
  margin-bottom: 16px;

  div:first-of-type {
    border-right: solid 1px #d8d8d8;
    padding-right: 8px;
    margin-right: 8px;
  }
}
.dateTime {
  display: flex;
  align-items: center;
  margin-right: 16px;
  span {
    margin-left: 2px;
  }
}
.link {
  color: $blue-primary-v100;
  font-size: 0.875rem;
  text-decoration: underline;
  font-weight: $fw-normal;
  cursor: pointer;
  margin-left: auto;

  @media screen and (max-width: 600px) {
    margin-left: 0px;
  }
}
.counterGrid {
  display: flex;
  justify-content: center;
  background-color: #dbf0f9;
  border-radius: $border-radius-md;
  width: 272px;
  height: 78px;
  padding-top: 14px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 58px;
  }

  p {
    font-size: 1.75rem;
    margin: 0px;
    font-weight: $fw-normal;
  }
  span {
    font-size: 0.875rem;
    font-weight: $fw-normal;
  }
}
