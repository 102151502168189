@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.noUpcomingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - $spacing-6);
  margin-top: 28px;
  color: #575757;
  text-align: center;
  @media screen and (max-width: $md) {
    margin-top: 0px;
  }
}
.bookButton {
  margin-top: 14px !important;
  background-color: #f9f9f9 !important;
}
